import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Person from "../components/person"
import '../styles/testimonials.scss'

const Testimonials = ({ data }) => (

  <Layout seoMetaTags={data?.datoCmsTestimonialspage?.seoMetaTags} page='Kokemuksia' >
    <div className='testimonials'>
      <div className='subtitle' dangerouslySetInnerHTML={{ __html: data?.datoCmsTestimonialspage?.bodyNode.childMarkdownRemark.html }} />
      {
        data?.datoCmsTestimonialspage?.testimonials.map((testimonial) => (
          <div className='testimonial' key={testimonial.id}>
            <div className='content'>
              <Person size='large' showTitle {...testimonial.person} />
              <div className='body' dangerouslySetInnerHTML={{ __html: testimonial.bodyNode.childMarkdownRemark.html }} />
            </div>
          </div>
        ))
      }
    </div>
  </Layout >
)
export const query = graphql`
  query TestimonialsQuery {
    datoCmsTestimonialspage {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      bodyNode {
        childMarkdownRemark {
          html
        }
      }
      testimonials {
        id
        person {
          ...personFields
        }
        bodyNode {
          childMarkdownRemark {
            html
          }
        }
        
      }
    }
  }
`

export default Testimonials
